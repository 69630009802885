/**
 * This header component is to be
 * shown only for a few seconds in desktop
 * and maybe shown in mobile view
 */

import React, { useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import NavigationItems from '@/components/DesignSystem/NavigationItems';
import { getNavigationPagesList, toggleRfqFormModal } from '@/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import router from 'next/router';
import { getRoute, PATH } from '@/utils/routes';
import ReactHamburger from '@/components/Common/ReactHamburger';
import useSaveHeaderHeight from '../../../../hooks/useSaveHeaderHeight';
import { useFeatureLocks } from '@/hooks/useFeatureLocks';
import { getStoreType } from '@/utils/getStoreType';
import { STORE_CUSTOMIZATION } from '@/utils/constants';
import { useSSRSelector } from '@/redux/ssrStore';
import MegaMenuDesktopView from '@/components/MegaMenu/MegaMenuDesktopView';
import { getVisibilityForHeader } from '@/utils/logoAndNameHelpers';
import useInstallAppOverlayActions from '@/components/InstallAppInstructionsOverlay/useInstallAppOverlayActions';
import { redirectToHomePage } from '@/utils/handleRedirectToHomePage';
import getActualOrDsStoreLogo from '@/utils/getActualOrDsStoreLogo';
import checkIfWebview from '@/utils/checkIfWebview';

const InstallAppInstructionsOverlay = dynamic(
  () => import('@/components/InstallAppInstructionsOverlay'),
  {
    ssr: false,
  }
);

const Header = () => {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const isWebview = checkIfWebview();
  const { openIntallAppOverlay } = useInstallAppOverlayActions();
  const [showMegaMenu, setShowMegaMenu] = useState(false);

  const { storeData, navigationList, catalogCategories, productTags } = useSSRSelector(
    (state) => ({
      storeData: state.storeReducer.store,
      navigationList: state.additionalPagesReducer.navigationList,
      catalogCategories: state.catalogReducer.categories,
      productTags: state.catalogReducer.productTags,
    })
  );

  const storeLogo = getActualOrDsStoreLogo(storeData);
  const { items } = useSelector((state) => ({
    items: state.cartReducer.items,
  }));
  const headerRef = useRef();
  useSaveHeaderHeight(headerRef);
  const { installAppFeatureStatus } = useFeatureLocks();

  useEffect(() => {
    if (storeData?.store_id) {
      dispatch(
        getNavigationPagesList({
          storeId: storeData?.store_id,
          isPublished: true,
        })
      );
    }
  }, [storeData?.store_id]);

  useEffect(() => {
    if (isOpen) {
      document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
    } else {
      document.getElementsByTagName('body')[0].style.overflowY = 'auto';
    }
  }, [isOpen]);

  const onHandlePageClick = (href) => {
    if (href === PATH.CART && getStoreType(storeData) === STORE_CUSTOMIZATION.B2B) {
      dispatch(toggleRfqFormModal());
      return;
    }
    if (href) {
      router.push(getRoute(href, storeData?.store_info?.domain));
    }
  };

  return (
    <article ref={headerRef} className="Header9">
      <div className="Header9__inner">
        <div className="Header9__inner--subsection">
          <div className="Header9__desktop Header9__name-logo-container">
            {storeLogo && getVisibilityForHeader(storeData?.theme?.store_logo_status) && (
              <img
                className="store-logo pointer"
                src={storeLogo}
                alt="store logo"
                onClick={() => {
                  redirectToHomePage(PATH.PRODUCT, storeData?.domain);
                }}
              />
            )}
            {getVisibilityForHeader(storeData?.theme?.store_name_status) && (
              <span className="store-name pointer">
                <span
                  onClick={() => {
                    redirectToHomePage(PATH.PRODUCT, storeData?.domain);
                  }}
                >
                  {storeData?.store_info?.name}
                </span>
              </span>
            )}
          </div>
          <div className="Header9__mobile">
            <ReactHamburger open={isOpen} setOpen={setOpen} theme="#000000" />
          </div>
          <div className="Header9__desktop">
            {navigationList?.length > 0 && (
              <NavigationItems
                categories={[...productTags, ...catalogCategories] || []}
                navigationItems={navigationList || []}
                toggleMegaMenu={() => setShowMegaMenu(!showMegaMenu)}
              />
            )}
          </div>
        </div>
        <div className="Header9__inner--subsection">
          <div
            className="flex items-center pointer"
            onClick={() => onHandlePageClick(PATH.SEARCH)}
          >
            <img src="/assets/icons/search.svg" width="16" />
            <span className="ml2" style={{ fontSize: 14 }}>
              Search
            </span>
          </div>
          <div
            className="ml5-ns ml4 cart-item br-pill flex items-center justify-center pointer"
            onClick={() => onHandlePageClick(PATH.CART)}
          >
            <span className="flex" style={{ fontSize: 14 }}>
              Cart ({items.length})
            </span>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className={`Header9__mobile navigation__container`}>
          <div className="navigation__container--inner">
            <NavigationItems
              categories={[...productTags, ...catalogCategories] || []}
              navigationItems={navigationList || []}
              closeNavigationOnMobile={() => setOpen(false)}
            />
            {!isWebview && installAppFeatureStatus?.isVisible && (
              <span
                className="pwa:tw-hidden"
                onClick={() => {
                  openIntallAppOverlay();
                }}
              >
                <div>Install App</div>
              </span>
            )}
          </div>
        </div>
      )}
      <InstallAppInstructionsOverlay />
      <MegaMenuDesktopView open={showMegaMenu} onClose={() => setShowMegaMenu(false)} />
    </article>
  );
};

export default Header;
